/**
 * Changes the header color per section
 */

const changeHeaderColorPerSection = () => {
  const brown = document.querySelectorAll('.js-brown')
  const white = document.querySelectorAll('.js-white')
  const header = document.querySelector('.header')
  const menu = document.querySelector('.nav')
  const navList = document.querySelector('.nav__list')
  const hamburger = document.querySelector('.hamburger')
  const extras = document.querySelector('.header__extra-link')
  const mainConcept = document.querySelector('#main-concept')
  const topBG = document.querySelector('.top__bg')

  for(let item of brown) {
    let controller = new ScrollMagic.Controller({addIndicators: true})

    let scene = new ScrollMagic.Scene({
      triggerElement: item
    }).addTo(controller);

    scene.on('enter', e => {
      header.classList.remove('header--white')
      menu.classList.remove('nav--white')
      navList.classList.remove('nav__list--white')
      hamburger.classList.remove('hamburger--white')
      extras.classList.remove('header__extra-link--white')


      if(mainConcept && mainConcept.classList.contains('active')) {
        topBG.classList.add('active')
      }
    });

    scene.on('leave', () => {
      header.classList.add('header--white')
      menu.classList.add('nav--white')
      navList.classList.add('nav__list--white')
      hamburger.classList.add('hamburger--white')
      extras.classList.add('header__extra-link--white')

      topBG.classList.remove('active')
    });
  }

  for(let item of white) {
    let controller = new ScrollMagic.Controller({addIndicators: true})

    let scene = new ScrollMagic.Scene({
      triggerElement: item
    }).addTo(controller);

    scene.on('enter', () => {
      header.classList.add('header--white')
      navList.classList.add('nav__list--white')
      hamburger.classList.add('hamburger--white')
      extras.classList.add('header__extra-link--white')
    });

    scene.on('leave', () => {
      header.classList.remove('header--white')
      navList.classList.remove('nav__list--white')
      hamburger.classList.remove('hamburger--white')
      extras.classList.remove('header__extra-link--white')
    });

    scene.triggerHook(0)
  }
}

export default changeHeaderColorPerSection
