/**
 * Initialize AOS
 */
const initializeAOS = () => {
  AOS.init({
    once: true,
    duration: 1000,
    disable: 'mobile'
  });
}

export default initializeAOS
