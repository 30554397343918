/**
 * Stop the fixed positioning of header when you scroll on footer
 */
const stopFixedHeaderOnFooter = () => {
  let header = document.querySelector('.header')
  let instagramLink = document.querySelector('.header__instagram-link')
  let container = instagramLink.getBoundingClientRect().top
  let footer = document.querySelector('.footer')
  let containerFooter = footer.getBoundingClientRect().top
  let nav = document.querySelector('.nav')

  if(screen.width > 767) {
    if((container + document.body.scrollTop) + instagramLink.offsetHeight >= (containerFooter + document.body.scrollTop) - 10) {
      header.classList.add('header--absolute')
      nav.classList.add('nav--absolute')
    }

    if(document.body.scrollTop + window.innerHeight < (containerFooter + document.body.scrollTop)) {
      header.classList.remove('header--absolute')
      nav.classList.remove('nav--absolute')
    }
  }
}

export default stopFixedHeaderOnFooter
