/**
 * Navigation Pointer
 */
const changeNavPointer = () => {
  const links = document.querySelectorAll('.nav__link');
  const top = document.getElementById('top');

  function removeActive() {
    for(let link of links) {
      if(link.classList.contains('active-page')) {
        link.classList.remove('active-page');
      }
    }    
  }

  const target_concept = document.querySelectorAll('.targetConcept')
  const target_top = document.querySelectorAll('.targetTop')
  const target_flow = document.querySelectorAll('.targetFlow')

  for(let item of target_concept) {
    let controller = new ScrollMagic.Controller({addIndicators: true})

    let scene = new ScrollMagic.Scene({
      triggerElement: item
    }).addTo(controller);

    scene.on('enter', () => {
      removeActive();
      links[1].classList.add("active-page");
    });

    scene.on('leave', () => {
      removeActive();
      links[0].classList.add("active-page");
    });
  }

  for(let item of target_top) {
    let controller = new ScrollMagic.Controller({addIndicators: true})

    let scene = new ScrollMagic.Scene({
      triggerElement: item
    }).addTo(controller);

    scene.on('enter', () => {
      removeActive();
      links[0].classList.add("active-page");
    });
  }

  for(let item of target_flow) {
    let controller = new ScrollMagic.Controller({addIndicators: true})

    let scene = new ScrollMagic.Scene({
      triggerElement: item
    }).addTo(controller);

    scene.on('enter', () => {
      removeActive();
      links[3].classList.add("active-page");
    });

    scene.on('leave', () => {
      removeActive();
      links[0].classList.add("active-page");
    });
  }
}

export default changeNavPointer
