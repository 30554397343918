/**
 * Plays the movie on click
 */
 const onYouTubeIframeAPIReady = () => {
  return new YT.Player('js-iframe', {
    videoId: '4kz6UBKsT6s',
    playerVars: {
      'rel': 0
    }
  });
}

const playMovie = () => {
  const button = document.querySelector('.js-play')
  const video = document.querySelector('.js-video')
  let player

  if(button) {
    button.addEventListener('click', () => {
      document.body.classList.add('overflow')
      video.classList.add('service__video-iframe--active')
      player = onYouTubeIframeAPIReady()
      player.playVideo()
    })

    video.addEventListener('click', () => {
      document.body.classList.remove('overflow')
      video.classList.remove('service__video-iframe--active')
      player.stopVideo();
    })
  }
}

export default playMovie
