/**
 * Intersection observer
 * - snap scroll detect if section is in view
 */

const intersectionObserver = () => {
  const targets = document.querySelectorAll('[data-observer]')

  const options = {
    rootMargin: '0px',
    threshold: 1.0
  }

  const addClass = (el) => {
    if (!el.classList.contains('is-visible')) {
      el.classList.add('is-visible')
    }
  }

  const removeClass = (el) => {
    if (el.classList.contains('is-visible')) {
      el.classList.remove('is-visible')
    }
  }

  const doThings = (entries, observer) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        addClass(entry.target)
      } else {
        removeClass(entry.target)
      }
    })
  }

  const observer = new IntersectionObserver(doThings, options)

  targets.forEach(target => {
    observer.observe(target)
  })
}

export default intersectionObserver
