/**
 * Price/option (top) using Flickity
 */

const optionTop = () => {
  var image = document.querySelector('.top__option-image');
  if(image) {
    new Flickity( image, {
      pageDots: false,
      wrapAround: true
    });
  }

  var content = document.querySelector('.top__option-content');
  if(content) {
    new Flickity( content, {
      cellAlign: 'left',
      pageDots: false,
      prevNextButtons: false,
      asNavFor: ".top__option-image",
      contain: true,
      wrapAround: true,
      fade: true
    });
  }
}

export default optionTop
