/**
 * Smooth scroll when anchor link is clicked
 */
const anchorSmoothScroll = () => {
  const anchorLink = document.querySelectorAll("a[href^='#']")

  for(let item of anchorLink) {
    item.addEventListener('click', (e) => {
      e.preventDefault();
      document.querySelector(item.getAttribute('href')).scrollIntoView({ behavior: 'smooth' })
    });
  }
}

export default anchorSmoothScroll
