/**
 * TOGGLE MENU
 * - show/hide menu
 * - toggle hamburger style
 */

const toggleMenu = () => {
  const burger = document.querySelector('.hamburger');
  const menu = document.querySelector('.nav');
  const anchor = document.querySelectorAll(".nav__link");

  burger.addEventListener('click', () => {
    burger.classList.toggle('menu-open');
    menu.classList.toggle('menu-open');
  })

  for(let link of anchor) {
    link.addEventListener('click', () => {
      burger.classList.remove('menu-open');
      menu.classList.remove('menu-open');
    })
  }
}

export default toggleMenu