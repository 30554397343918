/**
 * Concept text animation
 */

const conceptAnim = () => {
    $(function(){
      $('.text').children().addBack().contents().each(function() {
        $(this).replaceWith($(this).text().replace(/(\S)/g, '<p><span>$&</span></p>'));
      });
    });

    

}

export default conceptAnim