import intersectionObserver from "./components/intersectionObserver"
import initializeAOS from "./components/initializeAOS"
import anchorSmoothScroll from "./components/anchorSmoothScroll"
import changeHeaderColorPerSection from "./components/changeHeaderColorPerSection"
import stopFixedHeaderOnFooter from "./components/stopFixedHeaderOnFooter"
import optionTop from "./components/optionTop"
import toggleMenu from "./components/toggleMenu"
import conceptAnim from "./components/conceptAnim"
import conceptTransition from "./components/conceptTransition"
import changeNavPointer from "./components/changeNavPointer"
import ajaxZip from "./components/ajaxzip"
import playMovie from "./components/playMovie"
import changeBg from "./components/changeBg"
import initializeFlickity from "./components/initializeFlickity"

document.addEventListener(
  'DOMContentLoaded',
  () => {
    intersectionObserver()
    initializeAOS()
    anchorSmoothScroll()
    changeHeaderColorPerSection()
    optionTop()
    toggleMenu()
    conceptAnim()
    conceptTransition()    
    changeNavPointer()
    ajaxZip()
    playMovie()
    changeBg()
    initializeFlickity()

    window.onscroll = () => {
      stopFixedHeaderOnFooter()
    }
  },
  false
)
