/**
 * Ajax zip for contact forms
 */
 const ajaxZip = () => {
  const pref = document.querySelector("#pref");

  if(pref) {
    pref.addEventListener('keyup', function () {
      AjaxZip3.zip2addr(this,'','addr11','addr11');
    });
  }
}

export default ajaxZip
