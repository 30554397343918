/**
 * Concept transition animation
 */

const conceptTransition = () => {

    const conceptAnchor = document.querySelector('.anchor#concept');
    const conceptContent = document.querySelectorAll('.top__section--concept');
    const conceptOverlay = document.querySelector('.section-overlay');
  
      let controller = new ScrollMagic.Controller({addIndicators: true})
  
      let scene = new ScrollMagic.Scene({
        triggerElement: conceptAnchor
      }).addTo(controller);
  
      scene.on('enter', () => {
        conceptOverlay.classList.add('section-overlay--active');
        conceptOverlay.classList.remove('section-overlay--notactive');
        [].forEach.call(conceptContent, function(elem) {
            elem.classList.add('active')
            elem.classList.remove('notactive')
        });
      });
  
      scene.on('leave', () => {
        conceptOverlay.classList.remove('section-overlay--active');
        conceptOverlay.classList.add('section-overlay--notactive');
        [].forEach.call(conceptContent, function(elem) {
            elem.classList.remove('active')
            elem.classList.add('notactive')
        });
      });
    
}

export default conceptTransition