/**
 * Navigation Pointer
 */
 const changeBg = () => {
  const target_footer = document.querySelectorAll('.targetFooter')
  const bg = document.querySelector('.top__bg');

  for(let item of target_footer) {
    let controller = new ScrollMagic.Controller({addIndicators: true})
  
    let scene = new ScrollMagic.Scene({
      triggerElement: item
    }).addTo(controller);
  
    scene.on('enter', () => {
      bg.classList.add("change-dark");
      bg.classList.remove('active')
    });
  
    scene.on('leave', () => {
      bg.classList.remove("change-dark");
      bg.classList.add('active')
    });
  }
}

export default changeBg
