/**
 * about flickity
 */

 const initializeFlickity = () => {
  var about = document.querySelector('.about__slider');
  if(about) {
    let flkty = new Flickity( about, {
      prevNextButtons: false,
      fade: true,
      autoPlay: 5000,
      freeScroll: true,
      wrapAround: true,
      draggable: false
    });

    flkty.on('change', () => {
      flkty.stopPlayer();
      flkty.playPlayer();
    });

    about.addEventListener('mouseenter', () => {
      flkty.stopPlayer();
      flkty.playPlayer();
    });

    about.addEventListener('click', () => {
      flkty.stopPlayer();
      flkty.playPlayer();
    });

    about.addEventListener("touchstart", () => {
      console.log('touched');
      flkty.stopPlayer();
      flkty.playPlayer();
    });
  }

  var report = document.querySelector('.report-slider');
  if(report) {
    if(screen.width < 768) {
      let flkty = new Flickity( report, {
        wrapAround: true,
        adaptiveHeight: true
      });
    }
  }
}

export default initializeFlickity
